
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import BordPDF from "@/views/still/bord/pdf.vue"; 

import mAxiosApi from "@/api";
import { ElMessage, ElMessageBox } from 'element-plus'


export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
    BordPDF
  },
  props: {
    title: String,
    type: String,
    exclure: Array,
  },
  setup(props) {

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      masqueAll : 0,
    });
    const tableHeader = ref([
      { name: "ID", key: "bor_c_identifiant", sortable: false, className:"w-70px"},
      { name: "Cote Arkheïa", key: "bor_c_cote", sortable: false,},
      { name: "Producteur / Type", key: "act_c_raison_sociale", sortable: false,},
      { name: "Statut / Articles", key: "tot_article", sortable: false, className:"hide-small"},
      { name: "Modification", key: "bor_d_derniere_modification", sortable: false,  className:"hide-small"},
      { name: "",  key: "bor_n_seq", sortable: false,},
    ]);


    const duplicBord = (bor_n_seq) => {
      const form = {bor_n_seq: bor_n_seq}
      mAxiosApi
        .post("/duplicBord", form)
        .then( async(val: any) => {
          ElMessage({ type: 'success', message: `Bordereau dupliqué`})
          affDetail(val.data.results);
        })
    }

    const archiveBord = (bor_n_seq) => {
      const form = {bor_n_seq: bor_n_seq, bor_c_status: 'Supprimé', bor_n_readonly: 1}
      mAxiosApi
        .post("/editBord", form)
        .then( async() => {
          await loadList();
          ElMessage({ type: 'success', message: `Bordereau supprimé`})
        })
    }


    const changeCote = (bor_n_seq) => {
      ElMessageBox.prompt('Veuillez saisir la cote Arkheïa :', 'Cote Arkheïa', {
        confirmButtonText: 'Enregistrer',
        cancelButtonText: 'Annuler',
      })
        .then(({ value }) => {
          const form = {bor_n_seq: bor_n_seq, bor_c_cote: value}
          mAxiosApi
            .post("/editBord", form)
            .then( async() => {
              await loadList();
              ElMessage({ type: 'success', message: `Cote ${value} enregistrée avec succés !`})
            })
        })
    }


    const tableFilters = ref([
      { name: "Type", key: "bor_c_sort_versement", label: "bor_c_sort_versement", },
      { name: "Producteur", key: "act_c_raison_sociale", label: "act_c_raison_sociale", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD hh:mm:ss").fromNow();
    };
    
    const router = useRouter();

    const affDetail = (d) =>{
        localStorage.setItem("currentBord", d.bor_n_seq);

        const currentBordType = d.bor_c_sort_versement == 'Conservation intégrale' || d.bor_c_sort_versement == 'Conservation partielle' ? 'versement' : 'élimination'
        localStorage.setItem("currentBordType", currentBordType);

        store.dispatch(Actions.SET_CURRENT_BORD, {
          bordSeq: d.bor_n_seq,
          bordType : currentBordType,
        });

        router.push({ name: "bord" });
    }

    const loadList = async () => {
      const getSvurl = props.type=='current' ? "getSVMesBord" : "getSVHisto";

      let myListLiv = {} as any;
      let mFilter = localStorage.filterBordereau;
      //window.localStorage.setItem('filterBordereau', "");
      if (mFilter) {
        
        mFilter = mFilter.replace('/board/overview/', '');
        myListLiv = await getAxios("/"+ getSvurl +"/"+ mFilter);
        //console.log("myListLiv",myListLiv);
        if(myListLiv.results.length == 0) state.masqueAll = 1;

      } else {
        myListLiv = await getAxios("/" + getSvurl);
        console.log(myListLiv);
        if (props.exclure && props.exclure.length > 0) {
          myListLiv.results = myListLiv.results.filter(
            (item: any) => item.bor_c_status != props.exclure )
        }
      }

      state.myList = myListLiv.results;
      state.initialMyList = myListLiv.results;
      
      state.loadingDatatable += 2;

      state.loaderEnabled = false;
    }

    onMounted(async () => {  
      await loadList();
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      changeCote,
      archiveBord,
      duplicBord,
      pageTitle
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
