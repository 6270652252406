<template>
  <transition name="fade">
    <div class="card" v-if="!state.loaderEnabled&&state.masqueAll==0" style="width: 100%;">
      <div class="card-header border-0 pt-6">
        <div class="card-title" style="width:100%;">
          <h2>{{ title }} <span class="text-gray-600">{{ pageTitle }}</span></h2>
        </div>
        <SearchAndFilter
          v-if="state.initialMyList && !state.loaderEnabled"
          :listInitial="state.initialMyList"
          :filters="tableFilters"
          @returnSearch="state.myList = $event"
          @returnLoadingDatatable="state.loadingDatatable = $event"
        ></SearchAndFilter>
      </div>
      <div class="card-body pt-0">
        <div
          v-if="state.myList && state.myList.length == 0"
          class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
        >
          <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
            <inline-svg src="media/icons/duotune/general/gen048.svg" />
          </span>
          <div class="d-flex flex-column">
            <h4 class="mb-1 text-dark">Aucune ligne à afficher</h4>
            <span>Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.</span>
          </div>
        </div>
        <Datatable
          v-if="state.myList && state.myList.length > 0"
          :table-data="state.myList"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :rows-per-page="50"
          empty-table-text="Aucune ligne à afficher."
          :key="state.loadingDatatable || state.initialMyList"
          class="table-small-td"
          @click-function="affDetail($event)"
        >
          <template v-slot:cell-bor_c_identifiant="{ row: data }">{{ data.bor_c_identifiant }}</template>
          <template v-slot:cell-bor_c_cote="{ row: data }">

            <span v-if="data.bor_c_cote && type == 'current'" class="btn btn-sm btn-light-primary" @click="changeCote(data.bor_n_seq)" @click.stop title="Modifier la cote">{{ data.bor_c_cote }}</span>
            <span v-if="data.bor_c_cote && type == 'histo'" class="badge badge-sm badge-secondary">{{ data.bor_c_cote }}</span>
            <span v-if="!data.bor_c_cote"  class="btn btn-sm btn-light-danger" @click="changeCote(data.bor_n_seq)"  @click.stop title="Saisir la cote">Cote inexistante</span>
          </template>
          <template v-slot:cell-act_c_raison_sociale="{ row: data }">
            {{ data.act_c_raison_sociale }}<br><span class="badge badge-secondary mt-3 mb-3">{{ data.bor_c_sort_versement }}</span>
            <div class="display-small">
              <span class="badge badge-light-success bg-blue text-white fs-7">{{ data.bor_c_status }}</span><br><span class='badge badge-light mt-3 mb-3 fs-7'>{{ data.tot_article }} article(s)</span>            
            </div>
          </template>
          
          <template v-slot:cell-tot_article="{ row: data }"><span class="badge badge-light-success bg-blue text-white mt-3 fs-7">{{ data.bor_c_status }}</span><br><span class='badge badge-light mt-3 mb-3 fs-7'>{{ data.tot_article }} article(s)</span></template>
          <template v-slot:cell-bor_d_derniere_modification="{ row: data }"><span class='fs-7'>{{ formatDateAgo(data.bor_d_derniere_modification) }}</span></template>


          <template v-slot:cell-bor_n_seq="{ row: data }">
            <div style="width:120px;">
              <el-popconfirm v-if="type == 'current'" confirm-button-text="Oui, Supprimer le bordereau" confirm-button-type="danger" cancel-button-type="warning" cancel-button-text="Non"  title="Confirmez-vous la suppression du bordereau  ?"  @confirm="archiveBord(data.bor_n_seq)"  >
                <template #reference >  
                  <a v-if="type == 'current'" class="btn btn-sm btn-light-danger btn-custom-white mx-1 btn-mid-width suppr-bord">
                    <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;">
                      <inline-svg src="media/icons/duotune/arrows/arr061.svg"/> </span>
                  </a>
                </template>
              </el-popconfirm>

              <el-popconfirm confirm-button-text="Oui"  cancel-button-text="Non"  title="Confirmez-vous la duplication du bordereau ?"  @confirm="duplicBord(data.bor_n_seq)"  >
                <template #reference>  
                  <a class="btn btn-sm btn-custom-white mx-1 btn-mid-width">
                    <span class="svg-icon svg-icon-1 svg-icon-primary" style=" margin-right: 0rem;">
                      <inline-svg src="media/icons/duotune/general/gen054.svg"/> </span>
                  </a>
                </template>
              </el-popconfirm>


              <BordPDF title="" class="btn btn-sm btn-custom-white mx-1 btn-mid-width" styleSVG="margin-right: 0rem;" :bor_n_seq="data.bor_n_seq" />
              <!--<a class="btn btn-sm btn-custom-white mx-1" @click='affDetail(data)'>
                <span class="svg-icon svg-icon-1 svg-icon-blue">
                  <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                  Afficher
              </a>
              -->
            </div>
          </template>
        </Datatable>
      </div>
    </div>
  </transition>

</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import BordPDF from "@/views/still/bord/pdf.vue"; 

import mAxiosApi from "@/api";
import { ElMessage, ElMessageBox } from 'element-plus'


export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
    BordPDF
  },
  props: {
    title: String,
    type: String,
    exclure: Array,
  },
  setup(props) {

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      sourcePDF: "",
      mOffre: "",
      initialMyList: [] as any,
      loadingDatatable: 0,
      myListLiv: [],
      lSN: [],
      mObj: {},
      masqueAll : 0,
    });
    const tableHeader = ref([
      { name: "ID", key: "bor_c_identifiant", sortable: false, className:"w-70px"},
      { name: "Cote Arkheïa", key: "bor_c_cote", sortable: false,},
      { name: "Producteur / Type", key: "act_c_raison_sociale", sortable: false,},
      { name: "Statut / Articles", key: "tot_article", sortable: false, className:"hide-small"},
      { name: "Modification", key: "bor_d_derniere_modification", sortable: false,  className:"hide-small"},
      { name: "",  key: "bor_n_seq", sortable: false,},
    ]);


    const duplicBord = (bor_n_seq) => {
      const form = {bor_n_seq: bor_n_seq}
      mAxiosApi
        .post("/duplicBord", form)
        .then( async(val: any) => {
          ElMessage({ type: 'success', message: `Bordereau dupliqué`})
          affDetail(val.data.results);
        })
    }

    const archiveBord = (bor_n_seq) => {
      const form = {bor_n_seq: bor_n_seq, bor_c_status: 'Supprimé', bor_n_readonly: 1}
      mAxiosApi
        .post("/editBord", form)
        .then( async() => {
          await loadList();
          ElMessage({ type: 'success', message: `Bordereau supprimé`})
        })
    }


    const changeCote = (bor_n_seq) => {
      ElMessageBox.prompt('Veuillez saisir la cote Arkheïa :', 'Cote Arkheïa', {
        confirmButtonText: 'Enregistrer',
        cancelButtonText: 'Annuler',
      })
        .then(({ value }) => {
          const form = {bor_n_seq: bor_n_seq, bor_c_cote: value}
          mAxiosApi
            .post("/editBord", form)
            .then( async() => {
              await loadList();
              ElMessage({ type: 'success', message: `Cote ${value} enregistrée avec succés !`})
            })
        })
    }


    const tableFilters = ref([
      { name: "Type", key: "bor_c_sort_versement", label: "bor_c_sort_versement", },
      { name: "Producteur", key: "act_c_raison_sociale", label: "act_c_raison_sociale", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD hh:mm:ss").fromNow();
    };
    
    const router = useRouter();

    const affDetail = (d) =>{
        localStorage.setItem("currentBord", d.bor_n_seq);

        const currentBordType = d.bor_c_sort_versement == 'Conservation intégrale' || d.bor_c_sort_versement == 'Conservation partielle' ? 'versement' : 'élimination'
        localStorage.setItem("currentBordType", currentBordType);

        store.dispatch(Actions.SET_CURRENT_BORD, {
          bordSeq: d.bor_n_seq,
          bordType : currentBordType,
        });

        router.push({ name: "bord" });
    }

    const loadList = async () => {
      const getSvurl = props.type=='current' ? "getSVMesBord" : "getSVHisto";

      let myListLiv = {} as any;
      let mFilter = localStorage.filterBordereau;
      //window.localStorage.setItem('filterBordereau', "");
      if (mFilter) {
        
        mFilter = mFilter.replace('/board/overview/', '');
        myListLiv = await getAxios("/"+ getSvurl +"/"+ mFilter);
        //console.log("myListLiv",myListLiv);
        if(myListLiv.results.length == 0) state.masqueAll = 1;

      } else {
        myListLiv = await getAxios("/" + getSvurl);
        console.log(myListLiv);
        if (props.exclure && props.exclure.length > 0) {
          myListLiv.results = myListLiv.results.filter(
            (item: any) => item.bor_c_status != props.exclure )
        }
      }

      state.myList = myListLiv.results;
      state.initialMyList = myListLiv.results;
      
      state.loadingDatatable += 2;

      state.loaderEnabled = false;
    }

    onMounted(async () => {  
      await loadList();
    });

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      affDetail,
      changeCote,
      archiveBord,
      duplicBord,
      pageTitle
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
<style>



</style>